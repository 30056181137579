<template>
  <div class="home industry_home">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/banner/landed.png" alt="">
        </div>
      </el-col>
    </el-row>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
    <div class="introduce">
      <div class="padding-tb70">
        <!--          维佳地产-->
        <div class="WJgroup-titleBox flex">
          <p class="WJgroup-title">{{$t('property.title')}}</p>
        </div>
      </div>
    </div>
    <el-row class="introduce paddingb150" type="flex" justify="center">
      <el-col class="leftIno">

        <div>
          <router-link tag="a" to="/IndustryHouse" class="noactive ">
            <span class="font">{{$t('property.href1')}}</span>
          </router-link>
        </div>
        <div>
          <router-link tag="a" to="/IndustryCommercial" class="noactive ">
            <span class="font">{{$t('property.href2')}}</span>
          </router-link>
        </div>
        <div>
          <router-link tag="a" to="/IndustryProperty" class="noactive activeBox">
            <span class="font">{{$t('property.href3')}}</span>
          </router-link>
        </div>
      </el-col>
      <el-col class="rightIno  ">
        <div class="houseItitle flex align-end">
          <img class="houseItitleImg1" src="../../assets/image/Industry/img2.png" alt="">
          <h6>{{$t('property.href3')}}</h6>
          <p>{{$t('property.href3Desc')}}
            <img class="houseItitleImg2" src="../../assets/image/Industry/Star1.png" alt="">
          </p>
        </div>
        <div class="PropertyOneBox">
            <el-carousel :interval="5000" arrow="always" indicator-position='none'>
              <el-carousel-item v-for="(items,index) in newsList" :key="index">
                <div class="PropertyOne flex" v-if="items.length==2">
                  <div :class="{PropertyOL:i==0,PropertyOR:i==1}" v-for="(item,i) in items" :key="i" >
                    <img :src="item.litpic" alt="">
                    <p class="Propertyp">{{item.title}}</p>
                  </div>
                </div>
                <div class="PropertyOne flex" v-if="items.length==1">
                  <div  v-for="(item,i) in items" :key="i" style="width: 100%;" >
                    <img :src="item.litpic" alt="" style="object-fit: fill;width: 100%;">
                    <p class="Propertyp">{{item.title}}</p>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
        </div>

        <ul class="PropertyClass flex justify_between">
          <li v-for="(item,index) in typeList" :key="index" :class="pindex==index?'activePropertyC':''" @click="choseProperty(index)">{{item.typename}}</li>
        </ul>
        <div class="IndustryBox">
          <h3 class="IndustryTitle">{{$t('property.introduction')}}</h3>
          <p class="IndustryFont" v-html="typeInfo.content"></p>
        </div>
        <div class="IndustryBox">
<!--          行业宗旨-->
          <h3 class="IndustryTitle">{{$t('property.purposeTitle')}}</h3>
          <div class="IndustryPurpose" :style="{'letter-spacing':lang=='zh'?'':'0px'}">
            <p> “{{$t('property.purposeDesc1')}}</p>
            <p>{{$t('property.purposeDesc2')}}”</p>
          </div>
          <ul class="comContentRi-botimg flex">
            <li> <img src="../../assets/image/Property/img.png" alt="">
              <div class="comContentRi-botDiv">
                <h5>{{$t('property.purposet1')}}</h5>
                <h6>{{$t('property.purposetd1')}}</h6>
              </div>
            </li>
            <li> <img src="../../assets/image/Property/img2.png" alt="">
              <div class="comContentRi-botDiv">
                <h5>{{$t('property.purposet2')}}</h5>
                <h6>{{$t('property.purposetd2')}}</h6>
              </div>
            </li>
            <li> <img src="../../assets/image/Property/img3.png" alt="">
              <div class="comContentRi-botDiv">
                <h5>{{$t('property.purposet3')}}</h5>
                <h6>{{$t('property.purposetd3')}}</h6>
              </div>
            </li>
            <li> <img src="../../assets/image/Property/img4.png" alt="">
              <div class="comContentRi-botDiv">
                <h5>{{$t('property.purposet4')}}</h5>
                <h6>{{$t('property.purposetd4')}}</h6>
              </div>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
    <!-- 底部 -->
    <footerb></footerb>
  </div>
</template>

<script>
  export default {
    name: "Home",
    components: {},
    data() {
      return {
        swiperOptions: {
          autoplay: {
            delay: 1000,
            disableOnInteraction: true
          },

          loop: true,
          effect: "fade",
          fade: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
            // progressbarOpposite: true,
          }
        },

        menuActiveIndex: '/Industry',
        menu: {
          name: '产业布局',
          path: '/Industry',
          list: [{
            name: this.$t('menu.Property'),
            path: '/IndustryHouse',
          }, {
            name: this.$t('menu.park'),
            path: '/EntertainmentIndustrialPark',
          }, {
            name: this.$t('menu.entertainment'),
            path: '/Entertainment',
          }, {
            name: this.$t('menu.Commerce'),
            path: '/IndustryCommerce',
          }]
        },
        breadcrumb: [{
          name:this.$t('menu.layout'),
          path: '/Industry',
        }, {
          name:this.$t('menu.Property'),
          path: '/Industry',
        }, {
          name: this.$t('property.href3'),
          path: '//IndustryProperty',
        }],

        typeid:108,
        typeInfo:{},
        typeList:[],
        pindex:0,
        newsMap:{}
      };
    },
    computed: {
      newsList(){
        let newsList=[];
        if(this.typeList.length>0){
          let type =this.typeList[this.pindex];
          let newsItems = this.newsMap[type.id];
          if(newsItems&& newsItems.length>0){
            let nums = Math.ceil(newsItems.length/2);
            for(let i=0 ;i<nums ;i++){
              let items=[];
              for(let j=0 ;j<2;j++){
                let index = i*2 +j;
                if(newsItems.length> index){
                  items.push(newsItems[index]);
                }
              }

              newsList.push(this.$utils.translate(items));
            }
          }
        }

        return newsList;
      },
      lang(){
        return window.localStorage.getItem('language')
      },

    },
    created() {
      this.getTypeList();
    },
    mounted() {
      new this.$wow.WOW().init()
    },
    methods: {
      getTypeList(){
        this.$init.getChannelList(channelList=>{
          let typeList = channelList.filter(item=>{
            return item.parent_id==this.typeid
          });
          this.typeList =this.$utils.translate(typeList.slice(0,3));
          this.getData();
        })
      },
      getData(){
        let api='?m=api&c=v1.Api&a=index&_ajax=1';
        let type_arr = this.$utils.arrayChangeKey(this.typeList ,'id');
        type_arr = Object.keys(type_arr)
        let condition={};
        type_arr.forEach(item=>{
          condition['apiArclist_'+item] =`ekey=${item}&typeid=${item}&limit=100&addfields=typename_en,title_en`;
        })

        this.$request.post(api,{
          apiType_1:`ekey=1&typeid=${this.typeid}&addfields=content,title_en,content_en`,
          ...condition
        }).then(res=>{
          this.typeInfo = this.$utils.translate(res.apiType[1].data)

          let newsList=[];
          for(let typeid in res.apiArclist){
            if(res.apiArclist[typeid].data){
              newsList =newsList.concat(res.apiArclist[typeid].data)
            }
          }
          this.newsMap = this.$utils.arrayChangeKey(this.$utils.translate(newsList) ,'typeid' ,true)
        })
      },
      choseProperty(e){
        this.pindex=e
      },
      goto(item) {
        this.$router.push({
          path:'/IndustryHouseDetail',
          query:{id:item.aid}
        })
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .swiperIMg {
    width: 100%;
    height: auto;
  }

  .swiper-container {
    width: 100%;
    max-height: 670px;
  }

  .content {
    overflow: hidden;
  }

  .introduce {
    justify-content: center;
    max-width: 1400px;
    margin:0 auto;

    .leftIno {
      width: 312px;

      .noactive {
        display: inline-block;
        width: 100%;
        height: 116px;
        background: linear-gradient(90deg, #ADAFB2 0%, #8F9294 100%);
        // font-family: 'OPPOSans-M';
        font-style: normal;
        font-weight: 400;
        font-size: 29px;
        line-height: 40px;
        margin-bottom: 1px;
        padding-left: 50px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
      }

      .activeBox {
        height: 162px;
        background: linear-gradient(90deg, #E3CAA6 0%, #B5956F 100%);
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 28px;
          right: 0;
          width: 0;
          height: 0;
          border-right: 15px solid #fff;
          border-bottom: 15px solid transparent;
          border-top: 15px solid transparent;
          border-left: 15px solid transparent;
        }
      }
    }

    .rightIno {
      width: 986px;
      margin-left: 102px;
      .house_img {
        margin-top: 50px;
        position: relative;
        overflow: hidden;

        &:hover {
          .house_img_content {
            top: 0px;
          }

          .cover {
            opacity: 0;
          }
        }

        .house_img_content {
          position: absolute;
          top: -100%;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.19);
          // opacity: 0;
          transition: 0.5s;
          // top: 83px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .conFont {
          cursor: pointer;
          text-align: center;

          h2 {
            height: 28px;
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }

          p {
            height: 15px;
            margin-top: 18px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }

          img {
            margin-top: 21px;
          }
        }

        .cover {
          transition: 0.5s;
          width: 985px;
          height: 60px;
          line-height: 60px;
          background: rgba(0, 0, 0, 0.4);
          position: absolute;
          bottom: 0px;
          display: flex;

          h2 {
            height: 18px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin-left: 29px;
          }

          p {
            width: 185px;
            height: 14px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin-left: 23px;
          }
        }
      }


    }
  }



  .IndustryLookMore {
    width: 218px;
    height: 51px;
    border: 1px solid #A4825F;
    border-radius: 73px;
    margin: 88px auto 61px;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 51px;
    text-align: center;
    color: #A4825F;
    cursor: pointer;
  }
  .PropertyOneBox{
    margin-top: 43px;
    margin-bottom: 46px;
  }
 /deep/ .el-carousel__container{
     height: 310px;
  }
 /deep/ .el-carousel__arrow--left i{
    display: none;
  }
  /deep/ .el-carousel__arrow--left{
      width: 20px;
      height: 20px;
      border-top:1px solid #fff;
      border-left:1px solid #fff;
      transform: rotate(-45deg);
      border-radius: 0!important;
      background-color: rgba(0,0,0,0);
   }
   /deep/ .el-carousel__arrow--right i{
      display: none;
    }
   /deep/ .el-carousel__arrow--right{
       width: 20px;
       height: 20px;
       border-top:1px solid #fff;
       border-right:1px solid #fff;
       transform: rotate(45deg);
       border-radius: 0!important;
       background-color: rgba(0,0,0,0);
    }
  .PropertyOne {
    width: 100%;
    height: 310px;
    overflow: hidden;

    position: relative;
  }

  .PropertyOne div img {
    transition: 2s;
  }

  .PropertyOne div img:hover {
    transform: scale(1.1);
  }

  .PropertyOne .PropertyOL {
    cursor: pointer;
    width: 55%;
    height: 100%;
    clip-path: polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%);
    position: relative;
  }

  .PropertyOne .PropertyOR {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 55%;
    height: 100%;
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
    img{
      width: 100%;
    }
  }

  .PropertyOne .PropertyOL img {
    height: 100%;
    width: auto;

  }

  .PropertyTwo div img {
    transition: 2s;
  }

  .PropertyTwo div img:hover {
    transform: scale(1.1);
  }

  .PropertyTwo {
    width: 100%;
    height: 310px;
    overflow: hidden;
    margin-bottom: 46px;
    position: relative;
  }

  .Propertyp {
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    bottom: 0;
    margin-top: -16px;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height */
    color: #FFFFFF;
    text-align: center;
  }

  .PropertyTL {
    cursor: pointer;
    width: 40%;
    height: 100%;
    clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%);
    background-color: #A4825F;
    position: relative;
  }

  .PropertyTR {
    width: 40%;
    height: 100%;
    position: relative;

    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  .PropertyTR p {
    text-align: right;
  }

  .PropertyTM {
    width: 38.5%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 31%;
    overflow: hidden;
    background-color: #A4825F;
    clip-path: polygon(26% 0%, 100% 0%, 73% 100%, 0% 100%);
  }

  .PropertyTM p {
    width: 120%;
    left: -20%;
    text-align: center;


  }

  .PropertyTM img {

    margin-left: -1%;
  }

  .PropertyClass {
    margin-top: 24px;
  }

  .PropertyClass li {
    width: 30%;
    height: 46px;
    background: rgba(250, 245, 237, 0.7);
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 46px;
    color: #8F6B4B;
    text-align: center;
    cursor: pointer;
  }

  .activePropertyC {
    background: #AF8E68 !important;
    color: #fff !important;
    position: relative;
    cursor: pointer;
  }

  .activePropertyC::after {
    content: "";
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    width: 0px;
    height: 0px;
    border: 10px solid transparent;
    /*以下四个样式对应四种三角形，任选其一即可实现*/
    /* border-top-color:lightseagreen; */
    /* border-left-color:lightseagreen; */
    /* border-right-color:lightseagreen; */
    border-bottom-color: #AF8E68;
  }

  .IndustryPurpose {
    margin-top: 30px;
    font-family: 'PangMenZhengDao';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 18px;
    color: #A4825F;
  }

  .IndustryPurpose p {
    width: 72%;
    margin: 0 auto;
    padding: 10px 0;
  }

  .IndustryPurpose p:last-child {
    text-align: right;
  }

  .comContentRi-botimg {
    padding: 70px 0 0;
    flex-wrap: wrap;
  }

  .comContentRi-botimg li {
    overflow: hidden;
    width: 49%;
    height: 278px;
    position: relative;
    margin-bottom: 20px;

  }

  .comContentRi-botimg li:hover img {
    transform: scale(1.2);
  }

  .comContentRi-botimg li:nth-child(2n-1) {
    margin-right: 2%;
  }

  .comContentRi-botimg li img {
    width: 100%;
    height: 100%;
    transition: 2s;
  }

  .comContentRi-botDiv {
    position: absolute;
    bottom: 40px;
    left: 40px;
  }

  .comContentRi-botDiv h5 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
  }

  .comContentRi-botDiv h6 {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
  }
  @media screen and (min-width:320px) and (max-width:767px) {
    .industry_home .introduce{
      display: block;
      padding: 0 20px 70px;
      height: auto;
    }
    .industry_home .introduce .leftIno{
      width: 100%!important;
      display: flex;
      float: unset;
      margin-bottom: 40px;
    }
    .introduce .leftIno .noactive{
      justify-content: center;
      padding-left: 0;
      font-size: 39px;
    }
    .introduce .leftIno .activeBox{
      justify-content: center;
      padding-left: 0;
      font-size: 39px;
      height:116px;
    }

    .industry_home .introduce .leftIno div{
      width: 33%;
      text-align: center;
    }
    .industry_home .introduce .rightIno{
      width: 100%!important;
      margin-left: 0;
      float: unset;
    }

    .introduce .rightIno .title h2{
      font-size: 34px;
    }
    .introduce .rightIno .title p{
      font-size: 28px;
      line-height: 40px;
    }
    .commercial_wrap{
      min-height:calc(100vh - 600px);
    }
    //  ---------------------------
    .houseItitle{
      padding-bottom: 60px;
    }
    .houseItitle h6{
      font-size: 39px;
    }
    .houseItitle p{
      top:80px;
      font-size: 34px;
      line-height: 44px;
    }
    .Propertyp{
      font-size: 34px;
    }

    .introduce .PropertyClass li{
      height: 66px;
      line-height: 66px;
      font-size: 34px;
    }
    .introduce .activePropertyC::after{
      top:-44px
    }
    .introduce .IndustryTitle{
      font-size: 39px;
      line-height: 50px;
    }

    .introduce .comContentRi-botDiv h5{
      font-size: 34px;
      line-height: 44px;
      margin-bottom: 10px;
    }
    .introduce .IndustryPurpose p{
      font-size: 44px;
    }
  }
</style>
<style>
@media screen and (min-width:320px) and (max-width:767px) {
  .IndustryFont{
    font-size: 34px!important;
    line-height: 62px!important;
  }
}

</style>
